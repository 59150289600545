// PopUpButton.jsx
// DL 11/11/2k22

// Packages, Dependencies, and Shared Libraries
import React from "react";

// Constants
import { OPENAI_CREDIT } from "../../constants";

// React Components
import CareBubble from "./Care/CareBubble";
import CareContainer from "./Care/CareDetails";
import Bio from "./Bio/Bio";
import PlantImage from "../../Sprite/PlantImage";

// CSS
import "./Body.css";
import { useState } from "react";
import { useEffect } from "react";

// Images
import gardenerPlant from "../../Images/PlantBioPage/gardenerPlant.png";
import tanningPlant from "../../Images/PlantBioPage/tanning.png";

/*******************************************************************************
 * @func Body (React Component)
 * The body of the Bio Page. Is a parent to many other Plant Bio components.
 *
 * @param plant (object): The plant object that contains all the information
 * about our little, leafy friend queried from the DB
 * @param species (object): The shared species object queried from the DB
 * @param plantID: (string): The unique document ID of the plant
 * @param onLoadCallback (function): Callback function that fires whenever this
 * component loads. Used to tell the caller that this has finished rendering
 * and is ready to show.
 * @param scale (numeric): Scaling factor passed onto the PlantImage
 * subcomponent
 *
 * @notes plantID is currently not used because it is for the reporting features
 * which have been disabled for now
 *
 ******************************************************************************/
export const PopUpButton = ({
  style,
  buttonText,
  popUpText,
  image,
  plantName,
}) => {
  const [showPopUp, setShowPopUp] = useState(false);

  const addName = (string) => {
    return string;
  };

  const processText = (text) => {
    let newText = text.replaceAll("[PLANT_NAME]", plantName);
    return newText.split("\n").map((str) => <p>{str}</p>);
  };

  return (
    <React.Fragment>
      <button
        style={style}
        onClick={() => {
          setShowPopUp(true);
        }}
      >
        {buttonText}
      </button>
      {showPopUp ? (
        <div
          onClick={() => {
            setShowPopUp(false);
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            position: "fixed",
            width: "100%",
            height: "100%",
            backgroundColor: "rgb(0, 0, 0, 0.7)",
            top: "0",
            left: "0",
            zIndex: "10",
            fontSize: "12px",
            fontWeight: "normal",
            color: "black",
            textAlign: "left",
          }}
        >
          <div
            style={{
              display: "flex",
              padding: "15px",
              width: "80%",
              height: "fit-content",
              borderRadius: "10px",
              transform: "translateY(18vh)",
              backgroundColor: "var(--bodyBackgroundColor)",
              justifyContent: "space-between",
              flexDirection: "column",

              zIndex: "11",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                style={{
                  //xposition: "absolute",
                  top: "10",
                  width: "100px",
                  height: "100px",
                  marginBottom: "10px",
                }}
                src={image === "tanningPlant" ? tanningPlant : gardenerPlant}
                alt=""
              />
            </div>

            <div
              style={{
                backgroundColor: "white",
                borderRadius: "10px",
                padding: "16px 10px 0px 10px",
              }}
            >
              {processText(popUpText)}
            </div>
            <button
              onClick={() => {
                setShowPopUp(false);
              }}
              style={{
                backgroundColor: "green",
                width: "auto",
                border: "0px",
                color: "white",
                borderRadius: "5px",
                padding: "2px 16px 2px 16px",
                margin: "12px 25% 0 25%",
              }}
            >
              close
            </button>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
}; // End PopUpButton

export default PopUpButton;

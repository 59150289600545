// PlantImage.jsx
// TK 09/26/2k22

// Packages, Dependencies, and Shared Libraries
import React, { useRef } from "react";

import {
  smallPotWidth,
  mediumPotWidth,
  largePotWidth,
  IMAGE_TYPE,
} from "../constants"; // Pot width constants

// React Components
import PlantBodySubImage from "./PlantBodySubImage";
import SpriteSubImage from "./SpriteSubImage";

// CSS
import "./Sprite.css";

/*******************************************************************************
 * @func PlantImage (React Component)
 * The plant image component displays the plant and pot sprite to the user. This
 * component pulls the images from the firebase storage, then places the images
 * together to form the plant sprite.
 *
 * @param plant (object): The plant document's data from the DB
 * @param species (object): The species document's data from the DB
 * @param onLoadCallback (function): The function that will fire when the
 * component has finished loading. Usually used for showing the page or caller
 * component to the user.
 * @param width (string, optional): The width of the plant image. Must be
 * formatted as "Npx" where "N" is the width in px
 *
 * @notes None
 *
 ******************************************************************************/
const PlantImage = ({
  plant,
  species,
  onLoadCallback,
  width = "px",
  height = "px",
}) => {
  //Constants
  const loadCounter = useRef(0);

  /*****************************************************************************
   * @func imageLoaded
   * The OnLoad function for all the images. Increases the counter value that we
   * compare to decide if the image has finished loading
   * @param None
   * @return None
   *
   * @notes This will need to be updated to include the length of the props array
   * when that is finally introduced
   *
   ****************************************************************************/
  const imageLoaded = () => {
    loadCounter.current += 1;
    // The below if statement will need to be updated when props are reintroduced
    if (loadCounter.current >= 4) {
      onLoadCallback(true); // Pass back to the parent component
    }
  }; // End imageLoaded

  /*****************************************************************************
   * @func getPotWidth
   * Returns an object containing the width and height for the pot, eyes, and
   * mouth images. Dimensions are based on constants from global file
   * @param None
   * @return Pot Width (numeric): The width of the pot in px
   *
   * @notes This will probably have to be tweaked
   *
   ****************************************************************************/
  const getPotWidth = () => {
    // Scaling Factor inverse to plant size
    // Standard dimensions are 600x450 px (WxH)
    return species.stages[plant.stage].size === "small"
      ? largePotWidth
      : species.stages[plant.stage].size === "medium"
      ? mediumPotWidth
      : smallPotWidth;
  }; // End getPotWidth

  /*****************************************************************************
   * @func stylePlantImageContainer
   * Returns the style object for the container div based on the sprite
   * properties
   *
   * @param None
   * @return Container Style (Object): Contains the marginTop and width for the
   * image
   *
   * @notes None
   *
   ****************************************************************************/
  const stylePlantImageContainer = () => {
    return {
      transform:
        "translateY(" +
        String(100 * species.stages[plant.stage].plantPotOverlap) +
        "%)",
      zIndex: "7",
      /*marginBottom:
        "-" + String(100 * species.stages[plant.stage].plantPotOverlap) + "%",*/
    };
  }; // End stylePlantImageContainer

  /*****************************************************************************
   * @func checkForFixedWidth
   * Returns the width for the pot if it is passed in, otherwise void
   * @param None
   * @return
   * - If width is passed in, the formatted width string for the html
   * - If false, None
   *
   * @notes None
   *
   ****************************************************************************/
  const checkForFixedWidth = () => {
    // Optional width parameter, should be passed as a String "#px"
    if (width !== "px") {
      return { width: width };
    }
  };

  /*****************************************************************************
   * @func checkForFixedHeight
   * Returns the height for the pot if it is passed in, otherwise void
   * @param None
   * @return
   * - If height is passed in, the formatted height string for the html
   * - If false, None
   *
   * @notes None
   *
   ****************************************************************************/
  const checkForFixedHeight = () => {
    // Optional width parameter, should be passed as a String "#px"
    if (height !== "px") {
      return { height: height };
    }
  };

  return (
    <React.Fragment>
      {/* The JSX for the individual images that make up the sprite */}
      <div className="spriteArea" style={checkForFixedHeight()}>
        <div className="spriteBackground" style={checkForFixedWidth()}>
          {/* Empty div to use flexbox to move plantImage to bottom of container */}
          <div></div>
          <div className="spriteContainer">
            <div
              className="plantImageContainer"
              style={stylePlantImageContainer()}
            >
              <PlantBodySubImage
                plantImage={species.stages[plant.stage].plantBody}
                hasRenderedCallback={imageLoaded}
              />
            </div>

            <div className="potImageContainer" style={{ width: getPotWidth() }}>
              <SpriteSubImage
                plantImage={plant.sprite.pot.imageName}
                imageType={IMAGE_TYPE.pot}
                hasRenderedCallback={imageLoaded}
              />
              <SpriteSubImage
                plantImage={plant.sprite.mouth.imageName}
                imageType={IMAGE_TYPE.mouth}
                hasRenderedCallback={imageLoaded}
              />
              <SpriteSubImage
                plantImage={plant.sprite.eyes.imageName}
                imageType={IMAGE_TYPE.eyes}
                hasRenderedCallback={imageLoaded}
              />
            </div>
            {
              // Prop Stuff to be added later once flushed out
              /*plant.props.map((prop) => {
              return (
                <SpriteSubImage
                  plantImage={prop}
                  imageType={IMAGE_TYPE.prop}
                  hasRenderedCallback={imageLoaded}
                />
              );
            })*/
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}; // End PlantImage

export default PlantImage;

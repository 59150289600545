// OverviewEditor.jsx
// TK 9/11/2k22

// Packages, dependencies, and library functions
import React, { useState } from "react";
import { useEffect } from "react";

// CSS
import "./PlantEditor.css";

const OverviewEditor = ({ plant, species, plantUpdatedCallback }) => {
  // Constants
  const plantSpecies = [
    "Alocasia amazonica",
    "Dracaena trifasciata",
    "Echeveria elegans",
    "Pilea peperomioides",
    "Zamioculcas zamiifolia",
  ];

  useEffect(() => {
    return plantUpdatedCallback(plant);
  }, [plant]);

  return (
    <fieldset>
      <div className="plantFieldContainer">
        <label htmlFor="plantName" className="plantTextFieldLabel">
          Name
        </label>
        <textarea
          className="plantTextField"
          name="plantName"
          id="plantName"
          onChange={(e) => {
            plant.setName(e.target.value);
          }}
          defaultValue={plant.name}
        />
      </div>
      <div className="plantFieldContainer">
        <label htmlFor="speciesSelect" className="plantTextFieldLabel">
          Species
        </label>
        <select
          name="speciesSelect"
          id="speciesSelect"
          defaultValue={plant.species}
        >
          {plantSpecies.map((species, index) => {
            return (
              <option value={species} key={"speciesOption" + String(index)}>
                {species}
              </option>
            );
          })}
        </select>
      </div>
      <div className="plantFieldContainer">
        <label htmlFor="plantStage" className="plantTextFieldLabel">
          Plant Stage
        </label>
        <textarea
          className="plantTextField"
          name="plantStage"
          id="plantStage"
          defaultValue={plant.stage}
        ></textarea>
      </div>
    </fieldset>
  );
};

export default OverviewEditor;

// Console.jsx
// TK 7/25/2k22

// Packages, dependencies, and library functions
import React, { useState, useEffect } from "react";
import { auth } from "../Firebase";
import { onAuthStateChanged } from "firebase/auth";
import { Route, Routes } from "react-router-dom";

// React Components
import Login from "./Login/Login";
import ConsoleHome from "./Home/ConsoleHome";
import PlantEditor from "./PlantEditor/PlantEditor";
import PlantGeneration from "./PlantGeneration/PlantGeneration";
import TagGeneration from "./TagGeneration/TagGeneration";
import Analytics from "./Analytics/Analytics";
import CareInstructionsUpload from "./CareInstructionsUpload/CareInstructionsUpload";
import StagesUpload from "./SpeciesInfoUpload/SpeciesInfoUpload";

import PageNotFound from "../MiscPages/PageNotFound";

import "../App.css";

const Console = () => {
  // Hooks
  const [user, setUser] = useState({});

  // Wait/get the auth state
  useEffect(() => {
    authListener();
  }, []);

  // Listen for the auth state to change to fire a re-render of the home
  const authListener = () => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  };

  return (
    <React.Fragment>
      {user ? (
        <Routes>
          {/* List Valid Routes here */}
          <Route path="/" element={<ConsoleHome />} />
          <Route path="planteditor" element={<PlantEditor />} />
          <Route path="plantgeneration" element={<PlantGeneration />} />
          <Route path="taggeneration" element={<TagGeneration />} />
          <Route path="analytics" element={<Analytics />} />
          <Route
            path="careinstructionsupload"
            element={<CareInstructionsUpload />}
          />
          <Route path="speciesinfoupload" element={<StagesUpload />} />
          {/* No Match Case */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      ) : (
        <Login />
      )}
    </React.Fragment>
  );
};

export default Console;

// CareDetails.jsx
// DL 11/13/2k22

// Packages, Dependencies, Shared Libraries
import React, { useEffect, useState } from "react";
import { openInNewTab } from "../../../sharedFunctions";
import { getImageURLFromDatabase } from "../../../DatabaseOperations";
import PlantBodySubImage from "../../../Sprite/PlantBodySubImage";

// React Components
import CareInstruction from "./CareInstruction";

/*******************************************************************************
 * @func CareDetails (React Component)
 * This component shows the additional info for the care of the plant
 *
 * @param CareInstructions (object): Information about the care for the plant
 * @param species (string): The species of the plant
 *
 * @notes Temporary until we flesh out care intructions
 *
 ******************************************************************************/
export const CareDetails = ({ careInstructions, species }) => {
  return (
    <div>
      <button
        className="careDetailsButton"
        onClick={() => openInNewTab(careInstructions.adviceURL)}
      >
        Caring for <em>{species}</em>
      </button>
    </div>
  );
};

export const CareContainer = ({ careInstructions, species, imageName }) => {
  // Hooks
  const [showCare, setShowCare] = useState(false); // To Show the care instructions or not
  const [plantImage, setPlantImage] = useState(<img />);

  const NewlineText = (props) => {
    const text = props.text;
    return text.split("\n").map((str) => <p>{str}</p>);
  };

  useEffect(() => {
    getImageURLFromDatabase("spriteComponents/plants/" + imageName).then(
      (imageURL) => {
        setPlantImage(
          <img className="plantBody" src={imageURL} alt={"Plant Body"} />
        );
      }
    );
  }, [imageName]);

  // Used to rotate the arrow any time it's clicked
  // to expand or contact the instructions
  useEffect(() => {
    document.getElementById("arrow").className = showCare
      ? "arrowUp"
      : "arrowDown";
    document.getElementById("careHeader").className = showCare
      ? "careHeaderWhite"
      : "careHeaderColor";
  }, [showCare]);

  return (
    <div className="contentSection" id="careContainer">
      <div
        id="careHeader"
        onClick={() => {
          setShowCare(!showCare);
        }}
      >
        <div className="careTitle">
          Caring for&nbsp;<em>{species}</em>
        </div>
        <div className="arrow arrowDown" id="arrow"></div>
      </div>
      {showCare ? (
        <div className="careItems">
          <div className="careBlurbSection">
            <div className="careBlurb">{careInstructions.blurb}</div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  height: "90px",
                  width: "120px",
                  borderRadius: "45px",
                  marginRight: "10px",
                  backgroundColor: "white",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div />
                {plantImage}
              </div>
            </div>
          </div>

          <div className="careInstructions">
            <CareInstruction
              instruction={{
                displayString: careInstructions.waterDetails,
                isLink: false,
                link: "",
              }}
              image="water"
            />
            <CareInstruction
              instruction={{
                displayString: careInstructions.lightDetails,
                isLink: false,
                link: "",
              }}
              image="light"
            />
            <CareInstruction
              instruction={{
                displayString: careInstructions.moreDetails,
                isLink: false,
                link: "",
              }}
              image="plusSign"
            />{" "}
          </div>
        </div>
      ) : null}
    </div>
  );
}; // End CareContainer

export default CareContainer;

// SpriteSubImage.jsx
// DL 09/19/2k22

// Packages, Dependencies, and Shared Libraries
import React, { useEffect, useState } from "react";
import { getImageURLFromDatabase } from "../DatabaseOperations";

// CSS
import "./Sprite.css";

/*******************************************************************************
 * @func SpriteSubImage (React Component)
 * The component for individual sprite images (except for the plant body)
 *
 * @param plantImage (string): The image file name to be rendered
 * @param imageType (Enum): The type of the image (see constants.js)
 * @param hasRenderedCallback (function): The function that fires when the
 * image is retrieved from the DB and loaded. Usually increments a counter in
 * the caller component
 * @return JSX Element
 *
 * @notes None
 *
 ******************************************************************************/
const SpriteSubImage = ({ plantImage, imageType, hasRenderedCallback }) => {
  // Constants
  const basePath = "spriteComponents/"; // The firebase storage image directory

  // Hooks
  const [url, setURL] = useState(""); // The full firebase URL of the image

  // This fires when the plantImage changes, which is usually just upon the
  // initial render of the component. It retrieves the remote URL for the
  // image on the DB and sets the state variable
  useEffect(() => {
    getImageURLFromDatabase(basePath + getType().basePath + plantImage).then(
      (imageURL) => {
        setURL(imageURL);
      }
    );
    // eslint-disable-next-line
  }, [plantImage]); // End useEffect

  /*****************************************************************************
   * @func getType
   * Returns an object with information for rendering the image based on the
   * type
   *
   * @param None
   * @return myType object, with fields:
   * - class: the CSS of the image
   * - basePath: the base url of the subdirectory of the image
   * - alt: The alt name for the JSX image element
   *
   * @notes None
   *
   ****************************************************************************/
  const getType = () => {
    let myType = {};
    switch (imageType.name) {
      case "mouth":
        myType = { class: "mouth", basePath: "mouths/", alt: "Mouth" };
        break;
      case "eyes":
        myType = { class: "eyes", basePath: "eyes/", alt: "Eyes" };
        break;
      case "pot":
        myType = { class: "pot", basePath: "pots/", alt: "Pot" };
        break;
      case "prop":
      default:
        myType = { class: "prop", basePath: "props/", alt: "Prop" };
        break;
    }
    return myType;
  }; // End getType

  return (
    <img
      className={getType().class}
      src={url}
      alt={getType().alt}
      onLoad={hasRenderedCallback}
    />
  );
}; // End SpriteSubImage

export default SpriteSubImage;

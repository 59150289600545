// constants.js
// TK 09/26/2k22

/*******************************************************************************
 *
 * This file contains most of the constants that are displayed to the client.
 * These can be anything from paragraphs on the omepage to social media links
 * and enumerations
 *
 ******************************************************************************/

export const RENAMING_INFO =
  "If you'd like to change your plant's name permanently, just DM us!";

//Pantsonality info
export const PLANTSONALITY_DESC =
  "You can follow all the instructions, but every single plant is unique. " +
  "For the best care, observe [PLANT_NAME] carefully and see how they " +
  "react within your environment. Plant care is all about empathy. " +
  "We break the ice by generating [PLANT_NAME]’s  ~Plantsonality~ " +
  "to give you some talking points for whenever you two inevitably " +
  "start to chat.\n\n" +
  "Note: This bio is unique to each individual plant, " +
  "generated with AI. Please do not confuse anything said by the plant as guidance " +
  "for how to care for it in real life.";

// Home Page String Constants
export const HOME_PAGE_DESC =
  "Are you tired of your plants just being... well, plants? Tired of talking to your houseplants" +
  " only to be met with silence? Well, worry no more! With LeafLings technology, we give sentience to your " +
  "plants. Now they have their very own personalities! Whether you're looking for a shoulder to cry " +
  "on or just someone to talk to, LeafLings are here for you. And the best part is that they never judge, " +
  "they always listen, and they won't tell your secrets to anyone.";

export const INSTAGRAM_URL = "https://www.instagram.com/reallifeplants";

export const TIKTOK_URL = "https://www.tiktok.com/@reallifeplants";

export const OPENAI_CREDIT =
  "This plant was brought to life with help from openAI. Every plant is unique!";

// Enumerations

/*******************************************************************************
 * @enum PLANT_SIZE
 * This enumeration is used to define the plantSize member of the sprite object
 *
 * @notes Each accessing each member is like using an enumeration and can be
 * done as follows:     plantSize = PLANT_SIZE.small
 ******************************************************************************/
export class PLANT_SIZE {
  static small = new PLANT_SIZE("small");
  static medium = new PLANT_SIZE("medium");
  static large = new PLANT_SIZE("large");

  constructor(name) {
    this.name = name;
  }
}

export class IMAGE_TYPE {
  static mouth = new IMAGE_TYPE("mouth");
  static eyes = new IMAGE_TYPE("eyes");
  static pot = new IMAGE_TYPE("pot");
  static prop = new IMAGE_TYPE("prop");

  constructor(name) {
    this.name = name;
  }
}

/*******************************************************************************
 * @enum ZODIAC
 * This enumeration is used to define the zodiac sign for the Bio Bubbles
 *
 * @notes Each accessing each member is like using an enumeration and can be
 * done as follows:     myZodiacSign = ZODIAC.pisces
 *
 ******************************************************************************/
export class ZODIAC {
  static aries = new ZODIAC("Aries");
  static taurus = new ZODIAC("Taurus");
  static gemini = new ZODIAC("Gemini");
  static cancer = new ZODIAC("Cancer");
  static leo = new ZODIAC("Leo");
  static virgo = new ZODIAC("Virgo");
  static libra = new ZODIAC("Libra");
  static scorpio = new ZODIAC("Scorpio");
  static sagittarius = new ZODIAC("Sagittarius");
  static capricorn = new ZODIAC("Capricorn");
  static aquarius = new ZODIAC("Aquarius");
  static pisces = new ZODIAC("Pisces");

  constructor(name) {
    this.name = name;
  }
}

/*******************************************************************************
 * @enum BIOBUBBLE_TYPE
 * This enumeration is used to define the the different types of bio bubbles
 * that can be rendered to the user. It is pulled from the DB and used when
 * selecting the class
 *
 * @notes Each accessing each member is like using an enumeration and can be
 * done as follows:   myBioBubble = BIOBUBBLE_TYPE.image
 *
 ******************************************************************************/
export class BIOBUBBLE_TYPE {
  static image = new BIOBUBBLE_TYPE("image");
  static numeric = new BIOBUBBLE_TYPE("numeric");
  static color = new BIOBUBBLE_TYPE("color");
  static zodiac = new BIOBUBBLE_TYPE("zodiac");
  static music = new BIOBUBBLE_TYPE("music");

  constructor(name) {
    this.name = name;
  }
}

// Various sizing constants
export const smallPotWidth = "50%";
export const mediumPotWidth = "75%";
export const largePotWidth = "100%";

export const bioPlantContainerScale = 0.75;
export const homePagePlantContainerScale = 0.75;
export const plantContainerDefaultWidth_px = 300;
export const maximumPlantHeight = 365;

// Templates for QR Code and Plant Tag Generation
export const averyTemplates = [
  {
    id: "SL538",
    columns: 3,
    rows: 7,
    qrCodeSize: 60,
    gridTemplateColumns: "auto auto auto",
    gridContainerPadding: "28.175px 10.9px 7px 21.875px",
    gridItemMargin: "0px 0px 28.175px 0px",
    gridItemPadding: "6px 5px 6px 5px",
    gridItemHeight: "123px",
    gridItemWidth: "223.5px",
    //gridItemAlignment: "left",
    gridContainerWidth: 850,
    gridContainerHeight: 1100,

    hasSprite: true,

    secretCodeY: -42,
    qrTextGap: -42,
    showName: true,
  },
  {
    id: "SL1329",
    columns: 4,
    rows: 8,
    qrCodeSize: 50,
    gridTemplateColumns: "auto auto auto auto",
    gridContainerPadding: "22.175px 10.9px 26px 23.875px",
    gridItemMargin: "0px 0px 0px 0px",
    gridItemPadding: "6px 5px 6px 5px",
    gridItemHeight: "125px",
    //gridItemWidth: "200px",
    //gridItemAlignment: "left",
    gridContainerWidth: 850,
    gridContainerHeight: 1100,

    hasSprite: true,

    secretCodeY: -42,
    qrTextGap: -42,
    showName: true,
  },
];

//OLD Templates

/*{
    id: "Presta94220",
    columns: 3,
    rows: 8,
    qrCodeSize: 34,
    gridTemplateColumns: "auto auto auto",
    gridContainerPadding: "28px 12px 20px 18.5px",

    gridItemPadding: "3px 18px 11.25px 55px",
    gridItemAlignment: "right",
    gridContainerWidth: 340.5,
    gridContainerHeight: 441,

    hasSprite: true,

    secretCodeY: -57,
    qrTextGap: -60,
    showName: false,
  },
  {
    id: "Presta94106",
    columns: 4,
    rows: 5,
    qrCodeSize: 60,
    gridTemplateColumns: "auto auto auto auto",
    gridContainerPadding: "16px 12px 20px 12px",
    gridItemPadding: "16.75px 13.65px 11.75px 13.65px",
    gridItemAlignment: "right",
    gridContainerWidth: 380,
    gridContainerHeight: 492,

    hasSprite: false,

    secretCodeY: -42,
    qrTextGap: -42,
    showName: true,
  },*/

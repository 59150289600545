// BioBubble.jsx
// TK 8/15/2k22

// Packages, Dependencies, and Shared Libraries
import React, { useEffect, useState } from "react";

// CSS
import "./Bio.css";

/* BubbleItem Component
  Renders a single bubble item, which is the representation
  of the BioBubble abstract class.

  @param bioBubble (BioBubble Object): The Biobubble object that
  is used to render the bubble image. This will be one of the
  various child class instances and logic will vary based on
  bubble type.

*/
const BioBubble = ({ bioBubble, size = "90px" }) => {
  // Hooks
  const [valid, setValid] = useState(false); // Is the component valid?

  // Constants
  const images = require.context(
    "../../../Images/PlantBioPage/BubbleIcon",
    true
  ); // Image Context

  // Want to determine if the component is valid upon render
  // If it's not, then we won't show it...
  useEffect(() => {
    determineValidity();
    // eslint-disable-next-line
  }, []);

  /* determineValidity
    Determines whether the bubble object passed into the 
    component is valid and executes the appropriate logic
    to handle render the bubble.  This function is fired
    upon render of the component.

    @param None
    @return None (void)

    Note: This will be updated as time goes on.
    If we have a largen number of different types of
    bubbles, it will be wise to restructure this.
   */
  const determineValidity = () => {
    if (bioBubble.type !== undefined && bioBubble.text !== undefined) {
      switch (bioBubble.type) {
        case "numeric":
          if (bioBubble.value !== undefined) {
            setValid(true);
          }
          break;
        case "image":
        case "music":
        case "zodiac":
          // Get the imageURL for any case is an ImageBubble
          if (bioBubble.value !== undefined && bioBubble.value !== "") {
            setValid(true);
          }
          break;
        case "color":
          if (bioBubble.value !== undefined) {
            setValid(true);
          }
          break;
        default:
          break;
      }
    }
  }; // End determineValidity

  /* formatImageURL
    Formats the image URL so the relative path is correct.
    Supports URLs with relative paths included for backwards
    compatability. (before beta) 
    
    @param: None
    @return url (string): The relative URL for the image
   */
  const formatImageURL = () => {
    let url = bioBubble.value;
    const type = bioBubble.type;

    // For backwards compatibility -  remove the
    // Old folder if it's there.
    if (type !== "image") {
      let pathParts = url.split("/");
      url = pathParts[pathParts.length - 1];
    }

    // Add the folder path
    switch (type) {
      case "music":
        url = "MusicTypes/" + url;
        break;
      case "zodiac":
        url = "ZodiacIcons/" + url;
        break;
      // Pass image type thru cause its generic
      // and already has relative path
      default:
        break;
    }
    return url;
  }; // End formatImageURL

  /* drawCircle
    Returns an svg circle filled
    in with it's color.

    @param color (string): The color of the drawn circle
  */
  const drawCircle = (color) => {
    return (
      <div
        className="bubbleImage"
        style={{
          backgroundColor: color,
          width: "40px",
          height: "40px",
          borderRadius: "20px",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "black",
          marginTop: "6px",
        }}
      />
    );
  };

  /* getBubbleTop
    Determines how to display the top (nontext) section
    of the bubble. This logic is based on the type of the
    bubble passed in and called directly from the component's
    return statement.

    @param None
    @return myBubbleTop (jsx): The React Fragment that will
    render at the top of the bubble icon.

    Note: This will be updated as time goes on.
    If we have a largen number of different types of
    bubbles, it will be wise to restructure this.
  */
  const getBubbleTop = () => {
    let myBubbleTop;
    switch (bioBubble.type) {
      case "numeric":
        myBubbleTop = <p className="bioBubble">{bioBubble.value}</p>;
        break;
      case "image":
      case "music":
      case "zodiac":
        myBubbleTop = (
          <React.Fragment>
            <img
              className="bubbleImage"
              src={images("./" + formatImageURL())}
              alt={bioBubble.type}
            />
          </React.Fragment>
        );
        break;
      case "color":
        myBubbleTop = drawCircle(bioBubble.value);
        break;
      default:
        break;
    }
    return myBubbleTop;
  };

  return valid ? (
    <div
      className="bioBubble"
      /*style={{
        width: size,
        height: 0,
        paddingBottom: size,
      }}*/
    >
      <div className="bioBubbleText">{bioBubble.text}</div>
      {getBubbleTop()}
    </div>
  ) : null;
}; // End BubbleItem

export default BioBubble;

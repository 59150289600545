// PlantBioPage.jsx
// TK 10/29/2k22

// Packages, Dependencies, and Shared Libraries
import React, { useState, useEffect } from "react";
import {
  getPlantFromDatabase,
  getSpeciesFromDatabase,
  recordVisit,
} from "../DatabaseOperations";
import { useParams } from "react-router-dom";

import { openInNewTab } from "../sharedFunctions.js";

// Constants
import * as constants from "../constants";

// React Components
import BioPageHeader from "./Header/BioPageHeader";
import Loading from "../MiscPages/Loading";
import Body from "./Body/Body";
import Error from "../MiscPages/Error";

// Constants
import { bioPlantContainerScale } from "../constants";

// CSS
import "./BioPage.css";

/*******************************************************************************
 * @func PlantBioPage (React Component)
 * The component that displays the whole bio page for the plant:
 * - Sprite (plant image)
 * - Name and species
 * - Care Instructions
 * - Bio (Prompts/Responses, random facts)
 * - Bio Bubbles
 * - Reporting features
 *
 * @param None (see @notes section)
 * @return JSX Element
 *
 * @notes This component is called when the route /plant/:id is used, where :id
 * is the unique database identifier string. This is passed to the component
 * using the useParams() function. We set it to a state variable upon render of
 * the component.
 *
 ******************************************************************************/
const PlantBioPage = () => {
  // @TODO: Lots of hooks -> could we reduce the number of booleans and instead
  // use a string/enum for a state machine?
  // Hooks
  const [plantID] = useState(useParams().id); // Unique Firebase DB document id string
  const [plant, setPlant] = useState(null); // Plant Object
  const [species, setSpecies] = useState(null); // Species Object
  const [hasLoaded, setHasLoaded] = useState(false); // Whether the query promise from DB has returned and plant state is set
  const [isValid, setIsValid] = useState(false); // If the plant is valid (id exists in DB)
  const [error, setError] = useState(""); // Error String display to the user

  /*****************************************************************************
   * @func verifySprite
   * Verifies the sprite returned from the DB is valid and cleans the
   * object up to handle any DB descrepencies
   *
   * @param None
   * @return Promise
   * - On Resolve: Void
   * - On Reject: Returns the error string
   *
   * @notes Reject string is specific in the case we want to add error logging.
   * All errors will be cleaned and replaced with a generic error
   *
   ****************************************************************************/
  const verifySprite = () => {
    return new Promise((resolve, reject) => {
      if (!plant.sprite) {
        reject("Could not find sprite data member. Returned null.");
      }
      if (!plant.sprite.plantPotOverlap) {
        reject("Could not find plantPotOverlap data member. Returned null.");
      }
      if (!plant.sprite.eyes.imageName) {
        reject("Could not find eyes imageName data member. Returned null.");
      } else {
        if (plant.sprite.eyes.imageName === "") {
          reject("Eyes imageName data member was an empty string.");
        }
      }
      if (!plant.sprite.mouth.imageName) {
        reject("Could not find mouth imageName data member. Returned null.");
      } else {
        if (plant.sprite.mouth.imageName === "") {
          reject("Mouth imageName data member was an empty string.");
        }
      }

      if (!plant.sprite.pot.imageName) {
        reject("Could not find pot imageName data member. Returned null.");
      } else {
        if (plant.sprite.pot.imageName === "") {
          reject("Pot imageName data member was an empty string.");
        }
      }
      resolve();
    });
  };

  /*****************************************************************************
   * @func verifySpecies
   * Verifies the species returned from the DB is valid and cleans the
   * object up to handle any DB descrepencies
   *
   * @param None
   * @return Promise
   * - On Resolve: Void
   * - On Reject: Returns the error string
   *
   * @notes Reject string is specific in the case we want to add error logging.
   * All errors will be cleaned and replaced with a generic error
   *
   ****************************************************************************/
  const verifySpecies = () => {
    return new Promise((resolve, reject) => {
      // Check the whole Care Instructions object

      if (!species.careInstructions) {
        reject(
          "Could not find careInstructions data member for species [" +
            plant.species +
            "]. Returned null."
        );
      }

      // Check just the minimum that is needed to display to the user
      if (!species.careInstructions.adviceURL) {
        reject(
          "Could not find careInstructions adviceURL data member for species " +
            "[" +
            plant.species +
            "]. Returned null."
        );
      } else {
        if (species.careInstructions.adviceURL === "") {
          reject(
            "CareInstructions adviceURL data member was an empty string for " +
              "the species [" +
              plant.species +
              "]."
          );
        }
      }

      if (!species.careInstructions.lightShort) {
        reject(
          "Could not find careInstructions lightShort data member for species" +
            " [" +
            plant.species +
            "]. Returned null."
        );
      } else {
        if (species.careInstructions.lightShort === "") {
          reject(
            "CareInstructions lightShort data member was an empty string for " +
              "the species [" +
              plant.species +
              "]."
          );
        }
      }

      if (!species.careInstructions.waterShort) {
        reject(
          "Could not find careInstructions waterShort data member for species" +
            " [" +
            plant.species +
            "]. Returned null."
        );
      } else {
        if (species.careInstructions.waterShort === "") {
          reject(
            "CareInstructions waterShort data member was an empty string for " +
              "the species [" +
              plant.species +
              "]."
          );
        }
      }

      if (!species.careInstructions.difficulty) {
        reject(
          "Could not find careInstructions difficulty data member for species" +
            " [" +
            plant.species +
            "]. Returned null."
        );
      } else {
        if (species.careInstructions.difficulty === "") {
          reject(
            "CareInstructions difficulty data member was an empty string for " +
              "the species [" +
              plant.species +
              "]."
          );
        }
      }

      if (!species.stages[plant.stage].plantBody.imageName) {
        reject(
          "Could not find plantBody imageName data member for the species [" +
            plant.species +
            "] and stage [" +
            String(plant.stage) +
            "]. Returned null."
        );
      } else {
        if (species.stages[plant.stage].plantBody.imageName === "") {
          reject(
            "PlantBody imageName data member was an empty string for the" +
              " species [" +
              plant.species +
              "] and stage [" +
              String(plant.stage) +
              "]."
          );
        }
      }
      if (!species.stages[plant.stage].plantPotOverlap) {
        reject(
          "Could not find plantPotOverlap data member for the species [" +
            plant.species +
            "] and stage [" +
            String(plant.stage) +
            "]. Returned null."
        );
      }

      if (!species.stages[plant.stage].size) {
        reject(
          "Could not find size data member for the species [" +
            plant.species +
            "] and stage [" +
            String(plant.stage) +
            "]. Returned null."
        );
      } else {
        if (
          species.stages[plant.stage].size !== "small" ||
          species.stages[plant.stage].size !== "medium" ||
          species.stages[plant.stage].size !== "large"
        ) {
          reject(
            "PlantBody size data member [" +
              species.stages[plant.stage].size +
              "] was invalid for species [" +
              plant.species +
              "] and stage [" +
              String(plant.stage) +
              "]."
          );
        }
      }
      resolve();
    });
  };

  /*****************************************************************************
   * @func verifyPlant
   * Verifies the plant and species return from the DB is valid and cleans the
   * object up to handle any DB descrepencies
   *
   * @param None
   * @return Promise
   * - On Resolve: Void
   * - On Reject: Returns the error string
   *
   * @notes Reject string is specific in the case we want to add error logging.
   * All errors will be cleaned and replaced with a generic error
   *
   ****************************************************************************/
  const verifyPlant = () => {
    return new Promise((resolve, reject) => {
      // Check there is a stage for the plant

      if (!plant.stage) {
        // Default to 0
        plant.stage = 0;
      }

      console.log(plant);
      // Check Plant name exists and is not empty
      if (!(plant.name === "") && !plant.name) {
        reject("Plant name data member doesn't exist. Returned null.");
      }

      // Check species exists and is not empty
      if (!plant.species) {
        reject("Plant species data member doesn't exist. Returned null.");
      } else {
        if (plant.species === "") {
          reject("Plant species data member is empty string.");
        }
      }

      // Checks if the bio array exists and makes it empty if not
      if (!plant.bio) {
        plant.bio = []; // Make it an empty array so it displays nothing
      }
      // Checks if the bioBubbles array exists and makes it empty if not
      if (!plant.bioBubbles) {
        plant.bioBubbles = []; // Make it an empty array so it displays nothing
      }

      // Verify the sprite object is valid
      verifySprite().then(null, (spriteError) => {
        reject(spriteError);
      });

      // Verify the species object is valid
      verifySpecies().then(null, (speciesError) => {
        reject(speciesError);
      });

      resolve();
    });
  };

  // This is for when the error string updates. It sets the appropriate flags so
  // that the error page displays
  useEffect(() => {
    setIsValid(false);
    if (error !== "") {
      setHasLoaded(true);
      // @TODO: Log errors
    }
  }, [error]); // End useEffect

  // This fires after the species state updates. This looks to see if the
  // objects are valid (non-null)
  useEffect(() => {
    // Check if either are null still
    if (plant && species) {
      // Verify the plant and species objects returned are valid
      verifyPlant().then(
        () => {
          setIsValid(true);
        },
        (plantError) => {
          setError(plantError);
        }
      );
    }
  }, [species]); // End useEffect

  //  This useEffect fires upon load and retrieves the plant based on the ID
  // from the URL. If the ID is invalid, then the error will be displayed to the
  // user.
  useEffect(() => {
    if (plantID !== "") {
      // First get the plant object from the Plants Collection
      getPlantFromDatabase(plantID).then(
        (plant) => {
          setPlant(plant);
          // Then get the species of the plant from the Species Collection
          getSpeciesFromDatabase(plant.species).then(
            (species) => {
              setSpecies(species);
            },
            (reason) => {
              setError("Could not get species document: " + reason);
            }
          );
        },
        (reason) => {
          setError("Could not get plant document: " + reason);
        }
      );
    } else {
      setError("Plant ID was empty!");
    }
  }, [plantID]); // End useEffect

  return (
    <React.Fragment>
      <BioPageHeader />
      {!hasLoaded ? <Loading /> : null}
      <div
        className="plantBioPage"
        style={
          hasLoaded
            ? { display: "block", borderColor: "#ffe942" }
            : { display: "none" }
        }
      >
        {isValid ? (
          <React.Fragment>
            <Body
              plant={plant}
              species={species}
              plantID={plantID}
              onLoadCallback={setHasLoaded}
              scale={bioPlantContainerScale}
            />
            {/*<footer className="footer"></footer>*/}
          </React.Fragment>
        ) : (
          <Error error={error /*"Could not load Plant!"*/} />
        )}
        <div
          className="contactUs"
          onClick={() => openInNewTab(constants.INSTAGRAM_URL)}
        >
          <div className="line"></div>
          <div className="contactUs">DM Us!</div>
        </div>
      </div>
    </React.Fragment>
  );
}; // End PlantBioPage

export default PlantBioPage;

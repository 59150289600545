// Error.jsx
// TK 10/17/2022

// Packages, Dependencies, and Shared Libraries
import React from "react";
import { useNavigate } from "react-router-dom";

// Images
import plantAtComputer from "../Images/Graphics/plantAtComputer.png";

// CSS
import "./MiscPages.css";

/*******************************************************************************
 * @func Error (React Component)
 * The component that should be displayed whenever there is an error. It will
 * show a description of the error and display a graphic.
 *
 * @param error (string): The error from firebase that explains why the plant
 * cannot be loaded
 * @return JSX Element
 *
 * @notes None
 *
 ******************************************************************************/
const Error = ({ error }) => {
  // Constants
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className="errorText">{error}</div>
      <img
        className="errorImage"
        src={plantAtComputer}
        alt="Plant at computer"
      />
      <button className="randomPlantButton" onClick={() => navigate("/")}>
        Check Out a Random Plant!
      </button>
    </React.Fragment>
  );
}; // End Error

export default Error;

// ConsoleHomePageHeader.jsx
// TK 7/10/2k22

// Packages, dependencies, and library functions
import React from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../Firebase";

// CSS
import "./ConsoleHome.css";

const ConsoleHomePageHeader = () => {
  // Constants
  const navigate = useNavigate();

  // Logs out the user
  const logout = () => {
    auth.signOut();
  };

  return (
    <header className="pageHeader">
      <div
        className="goHome"
        onClick={() => {
          navigate("/console");
        }}
      >
        Go to Console Home
      </div>
      <input
        className="signOut"
        onClick={logout}
        type="button"
        value="Sign Out"
      />
    </header>
  );
};

export default ConsoleHomePageHeader;

// PlantEditor.jsx
// TK 9/26/2k22

// Packages, dependencies, and library functions
import React, { useState } from "react";
import {
  getPlantFromDatabase,
  getSpeciesFromDatabase,
} from "../../DatabaseOperations";

// React Components
import ConsoleHomePageHeader from "../Home/ConsoleHomePageHeader";
import EditPlant from "./EditPlant";

// CSS
import "./PlantEditor.css";

const PlantEditor = () => {
  // Hooks
  const [plantID, setPlantID] = useState("");
  const [plant, setPlant] = useState(null);
  const [species, setSpecies] = useState(null);
  const [error, setError] = useState("");

  const getPlant = () => {
    // First get the plant object from the Plants Collection
    getPlantFromDatabase(plantID).then(
      (plant) => {
        setPlant(plant);
        // Then get the species of the plant from the Species Collection
        getSpeciesFromDatabase(plant.species).then(
          (species) => {
            setSpecies(species);
          },
          (reason) => {
            setError(reason);
          }
        );
      },
      (reason) => {
        setError(reason);
      }
    );
  };

  return (
    <div>
      <ConsoleHomePageHeader />
      <div className="title">Plant Editor</div>
      <input
        className="textbox plantIDTextbox"
        placeholder="Plant ID"
        onChange={(e) => {
          setPlantID(e.target.value);
        }}
        type="text"
      ></input>
      <input
        className="getPlantButton"
        type="submit"
        value="Get Plant"
        onClick={getPlant}
      ></input>
      {error !== "" ? <div>{error}</div> : null}
      <EditPlant plant={plant} species={species} />
    </div>
  );
};

export default PlantEditor;

// PageNotFound.jsx
// TK 04/03/2022

// Packages, dependencies, and shared libraries
import React from "react";

// React Components
import PlantBioPageHeader from "../BioPage/Header/BioPageHeader";
import Error from "./Error";

// CSS
import "./MiscPages.css";

/*******************************************************************************
 * @func PageNotFound (React Component)
 * The component that should be displayed whenever the url does not resolve to
 * any of the other pages
 *
 * @param None
 * @return JSX Element
 *
 * @notes None
 *
 ******************************************************************************/
const PageNotFound = () => {
  return (
    <React.Fragment>
      <PlantBioPageHeader />
      <Error error={"Page not found!"} />
      <div className="line"></div>
      <div className="contactUs">DM Us!</div>
    </React.Fragment>
  );
}; // End PageNotFound

export default PageNotFound;

// Body.jsx
// DL 11/11/2k22

// Packages, Dependencies, and Shared Libraries
import React from "react";

// Constants
import { OPENAI_CREDIT, RENAMING_INFO } from "../../constants";

// React Components
import CareBubble from "./Care/CareBubble";
import CareContainer from "./Care/CareDetails";
import Bio from "./Bio/Bio";
import PlantImage from "../../Sprite/PlantImage";

// CSS
import "./Body.css";
import { useState } from "react";
import { useEffect } from "react";
import PopUpButton from "./PopUpButton";

/*******************************************************************************
 * @func Body (React Component)
 * The body of the Bio Page. Is a parent to many other Plant Bio components.
 *
 * @param plant (object): The plant object that contains all the information
 * about our little, leafy friend queried from the DB
 * @param species (object): The shared species object queried from the DB
 * @param plantID: (string): The unique document ID of the plant
 * @param onLoadCallback (function): Callback function that fires whenever this
 * component loads. Used to tell the caller that this has finished rendering
 * and is ready to show.
 * @param scale (numeric): Scaling factor passed onto the PlantImage
 * subcomponent
 *
 * @notes plantID is currently not used because it is for the reporting features
 * which have been disabled for now
 *
 ******************************************************************************/
const Body = ({ plant, species, plantID, onLoadCallback, scale }) => {
  // Hooks
  const [careBubbles, setCareBubbles] = useState([]);

  // This fires when the species object updates to create the care instruction
  // bio bubbles
  useEffect(() => {
    setCareBubbles([
      {
        instruction: species.careInstructions.waterShort,
        image: "water",
      },
      {
        instruction: species.careInstructions.lightShort,
        image: "light",
      },
      {
        instruction: species.careInstructions.difficulty,
        image: "plusIcon",
      },
    ]);
  }, [species]); // End useEffect

  return (
    <React.Fragment>
      <div className="sayHelloTo">Say "Hello!" to...</div>
      <div className="body">
        <div className="name">
          <b>{plant.name}</b>

          <div
            style={{
              marginTop: "-7px",
            }}
          >
            {/*<PopUpButton
              style={{
                border: "3px solid green",
                fontSize: "18px",
                fontWeight: "bold",
                height: "36px",
                width: "36px",
                marginLeft: "5px",
                marginRight: "15px",
                borderRadius: "50%",
                color: "green",
                padding: "0",

                backgroundColor: "var(--bodyBackgroundColor)",
              }}
              buttonText="?"
              popUpText={RENAMING_INFO}
              image="tanningPlant"
              plantName={plant.name}
            />*/}
          </div>

          {/*<p className="species">{plant.getPlantType()}</p>*/}
        </div>
        {/*<p className="speciesNickname">{plant.commonName}</p>*/}
        <div className="spriteAndBubbles">
          <div></div>
          <PlantImage
            plant={plant}
            species={species}
            onLoadCallback={onLoadCallback}
            scale={scale}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div></div>
            <div className="careBubbleContainer">
              {careBubbles.map((data, index) => {
                return (
                  <CareBubble
                    key={"bioBubble" + String(index + 1)}
                    data={data}
                  ></CareBubble>
                );
              })}
            </div>
          </div>
        </div>

        <CareContainer
          careInstructions={species.careInstructions}
          species={plant.species}
          imageName={species.stages[0].plantBody.imageName}
        />

        {/*<p className="contentSectionHeader">About me</p>  */}
        <Bio plant={plant} />
        <div className="Gpt3Message">{OPENAI_CREDIT}</div>
        {/* Leaving Out Rating scale for now, until we want to use it
          <RatingScale plantID={plantID} plantName={plant.getName()}></RatingScale>*/}
      </div>
    </React.Fragment>
  );
}; // End PlantBio

export default Body;

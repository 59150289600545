// SocialMediaIcon.jsx
// TK 03/21/2k22

// Packages, Dependencies, and Shared Libraries
import React from "react";
import { openInNewTab } from "../sharedFunctions";

// CSS
import "./home.css";

/*******************************************************************************
 * @func SocialMediaIcon (React Component)
 * Contains an image that when clicked, takes the user to the social media page
 * in a separate window
 * @param logoRelPath (string): Relative path to the image to be displayed
 * @param link (string): Link to the social media page
 *
 * @notes None
 *
 ******************************************************************************/
const SocialMediaIcon = ({ logoRelPath, link }) => {
  return (
    <div className="socialMediaIcon">
      <img src={logoRelPath} alt={link} onClick={() => openInNewTab(link)} />
    </div>
  );
}; // End SocialMediaIcon

export default SocialMediaIcon;

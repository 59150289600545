// PlantGeneration.jsx
// TK 7/12/2k22

import React from "react";

// Components
import ConsoleHomePageHeader from "../Home/ConsoleHomePageHeader";

const PlantGeneration = () => {
  return (
    <div>
      <ConsoleHomePageHeader />
      <div>This will be the plant Generation Station</div>
    </div>
  );
};

export default PlantGeneration;

// index.js
// TK 03/19/2k22

// Packages, Dependencies, and Shared Libraries
import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";

// CSS
import "./index.css";

// React Components
import App from "./App";

// Render the App component
// Routing logic takes place there
const routing = (
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Render the JSX above
ReactDOM.render(routing, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// BioPairsEditor.jsx
// TK 09/13/2022

// Packages, dependencies, and library functions
import React from "react";

// CSS
import "./PlantEditor";

const BioPairsEditor = ({ bioPairs }) => {
  return bioPairs.map((promptResponse, index) => {
    return (
      <React.Fragment key={String(index)}>
        <label
          htmlFor={"bioPair" + String(index)}
          className="plantTextFieldHeader"
        >
          Bio Pair {String(index + 1)}
        </label>
        <div className="plantFieldContainer" name={"bioPair" + String(index)}>
          <label
            htmlFor={"plantBioPrompt" + String(index)}
            className="plantTextFieldLabel"
          >
            Prompt
          </label>
          <textarea
            className="plantPromptTextbox"
            name={"plantPrompt" + String(index)}
            id={"plantBioPrompt" + String(index)}
            defaultValue={promptResponse.prompt}
          />
          <label
            htmlFor={"plantBioResponse" + String(index)}
            className="plantTextFieldLabel"
          >
            Response
          </label>
          <textarea
            className="plantResponseTextbox"
            name={"plantBioResponse" + String(index)}
            id={"plantBioResponse" + String(index)}
            defaultValue={promptResponse.response}
          ></textarea>
        </div>
      </React.Fragment>
    );
  });
};

export default BioPairsEditor;

// ConsoleHome.jsx
// TK 6/28/2022

// Packages, dependencies, and library functions
import React from "react";
import { useNavigate } from "react-router-dom";

// React Components
import HomePageHeader from "./ConsoleHomePageHeader";

const ConsoleHome = () => {
  // Constants
  const navigate = useNavigate();

  return (
    <div>
      <HomePageHeader />
      <div className="container">
        <div
          className="item"
          onClick={() => {
            navigate("planteditor");
          }}
        >
          Plant Editor
        </div>
        <div
          className="item"
          onClick={() => {
            navigate("plantgeneration");
          }}
        >
          Plant Generation
        </div>
        <div
          className="item"
          onClick={() => {
            navigate("analytics");
          }}
        >
          Analytics
        </div>
        <div
          className="item"
          onClick={() => {
            navigate("taggeneration");
          }}
        >
          Tag Generation
        </div>
        <div
          className="item"
          onClick={() => {
            navigate("careinstructionsupload");
          }}
        >
          Care Instructions Upload
        </div>
        <div
          className="item"
          onClick={() => {
            navigate("speciesinfoupload");
          }}
        >
          Species Info Upload
        </div>
      </div>
    </div>
  );
};

export default ConsoleHome;

// PlantBodySubImage.jsx
// DL 09/19/2k22

// Packages, Dependencies, and Shared Libraries
import React, { useEffect, useState } from "react";
import { getImageURLFromDatabase } from "../DatabaseOperations";

// CSS
import "./Sprite.css";

/*******************************************************************************
 * @func PlantBodySubImage (React Component)
 * The component for the plant body portion of the sprite
 *
 * @param plantImage (string): The image file name to be rendered
 * @param hasRenderedCallback (function): The function that fires when the image
 * is retrieved from the DB and loaded. Usually increments a counter in the
 * caller component
 * @return JSX Element
 *
 * @notes None
 *
 ******************************************************************************/
const PlantBodySubImage = ({ plantImage, hasRenderedCallback }) => {
  // Constants
  const basePath = "spriteComponents/plants/"; // Base path used to get the remote plants
  // Hooks
  const [url, setURL] = useState(""); // The remote Firebase strorage URL of the image

  // This fires when the plantImage changes, which is usually just upon
  // the initial render of the component. It retrieves the remote URL
  // for the image on the DB and sets the state variable
  useEffect(() => {
    getImageURLFromDatabase(basePath + plantImage.imageName).then(
      (imageURL) => {
        setURL(imageURL);
      }
    );
  }, [plantImage]); // End useEffect

  return (
    <img
      className="plantBody"
      src={url}
      alt={"Plant Body"}
      onLoad={hasRenderedCallback}
    />
  );
}; // End PlantBodySubImage

export default PlantBodySubImage;

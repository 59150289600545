// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyArTeF9qEyQ_0ApwfBuxOCQhGbo-fohLy0",
  authDomain: "plants-5f51e.firebaseapp.com",
  projectId: "plants-5f51e",
  storageBucket: "plants-5f51e.appspot.com",
  messagingSenderId: "867733442805",
  appId: "1:867733442805:web:a7cfd6d54c59417b3975bc",
  measurementId: "G-3C1BSVF8BQ",
};

const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
export const auth = getAuth(app);

export default getFirestore();

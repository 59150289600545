// PlantEditor.jsx
// TK 9/26/2k22

// Packages, dependencies, and library functions
import React, { useState } from "react";
import {
  getPlantFromDatabase,
  getSpeciesFromDatabase,
  updateDB,
} from "../../DatabaseOperations";

// React Components
import ConsoleHomePageHeader from "../Home/ConsoleHomePageHeader";

// CSS
import "./PlantEditor.css";

import * as csv from "csvtojson";

const CareInstructionsUpload = () => {
  // Hooks
  const [CSV, setCSV] = useState("");
  //const [plant, setPlant] = useState(null);
  const [species, setSpecies] = useState(null);
  const [error, setError] = useState("");

  const uploadInstructions = () => {
    //console.log(CSV);
    var careInstructionsSet = [];
    //convert CSV to JSON
    csv()
      .fromString(CSV)
      .then((jsonObj) => {
        console.log(jsonObj);
        careInstructionsSet = jsonObj;
        careInstructionsSet.map((careInstructions) => {
          //var waterCadenceToNumber = careInstructions["waterCadence"]
          careInstructions["waterCadence"] = parseInt(
            careInstructions["waterCadence"]
          );
          updateDB(careInstructions["species"], "Species", {
            careInstructions: careInstructions,
          });
        });
      });
    //extract species to an array
    //for each species in the CSV, update data of respective careInstructions,
    // Then get the species of the plant from the Species Collection

    /*updateDB(species[0], "Species", {
      testy: "toast",
      careInstructions: { waterRating: "oof" },
    });*/
    //check for error or print the successful result

    // First get the plant object from the Plants Collection
    /*getPlantFromDatabase(plantID).then(
      (plant) => {
        setPlant(plant);
       
      },
      (reason) => {
        setError(reason);
      }
    );*/
  };

  //Func to add height, imageName, and x, y, and overlap for a given species

  return (
    <div>
      <ConsoleHomePageHeader />
      <div className="title">Care Instructions Upload</div>
      <textarea
        className="textbox plantIDTextbox"
        placeholder="Care Instruction CSV"
        onChange={(e) => {
          setCSV(e.target.value);
        }}
        type="text"
      ></textarea>
      <input
        className="getPlantButton"
        type="submit"
        value="Upload Instructions"
        onClick={uploadInstructions}
      ></input>
      {error !== "" ? <div>{error}</div> : null}
    </div>
  );
};

export default CareInstructionsUpload;

import React from "react";
import QRCode from "./QRCode";

import "./QRCodeGenerator.css";
import qrCodeFaceLogo from "./qrCodeFaceLogo.png";
import qrCodeFaceLogo2 from "./qrCodeFaceLogo2.png";
import leafLingsAppIcon from "./leafLingsAppIcon.jpeg";

import { averyTemplates } from "../../constants";
import PlantImage from "../../Sprite/PlantImage";
import { getPlantFromDatabase } from "../../DatabaseOperations";

import GridItem from "./GridItem";

const QRCodeLayout = (props) => {
  const { plants, downloadRef, selectedTemplate } = props;

  const template = averyTemplates[selectedTemplate];

  //console.log(template);

  return (
    //grid
    <div
      className="grid-container"
      ref={downloadRef}
      style={{
        width: template.gridContainerWidth,
        height: template.gridContainerHeight,
        padding: template.gridContainerPadding,

        gridTemplateColumns: template.gridTemplateColumns,

        backgroundColor: "rgb(255, 240, 207)",
        backgroundColor: "white",
      }}
    >
      {plants.map((plant) => {
        return <GridItem plant={plant} template={template} />;
      })}
    </div>
  );
};

export default QRCodeLayout;

// Login.jsx
// TK 06/27/2k22

// Packages, dependencies, and library functions
import React, { useState } from "react";
import { auth } from "../../Firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { getUserByEmail } from "../../DatabaseOperations";

// CSS
import "./Login.css";

const Login = () => {
  // Hooks
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const login = (e) => {
    e.preventDefault();
    getUserByEmail(email).then(
      (user) => {
        if (user.isAdmin !== undefined && user.isAdmin) {
          signInWithEmailAndPassword(auth, email, password);
        } else {
          setError(
            "Cannot login to console. Contact a system admin for assistance."
          );
        }
      },
      (reason) => {
        setError(
          "Cannot login to console. Contact a system admin for assistance."
        );
      }
    );
  };

  return (
    <React.Fragment>
      <div className="loginText">Login To Leaflings Console</div>
      <input
        className="loginEntry"
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        placeholder="Email"
        type="email"
        name="email"
      />
      <input
        className="loginEntry"
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        placeholder="Password"
        type="password"
        name="password"
      />
      <input
        className="loginButton"
        type="submit"
        value="Login"
        onClick={login}
      ></input>
      {error !== "" ? <div className="loginErrorMessage">{error}</div> : null}
    </React.Fragment>
  );
};

export default Login;

// EditPlant.jsx
// TK 7/31/2k22

import React, { useState } from "react";

// React Components
import PlantImage from "../../Sprite/PlantImage";
import Loading from "../../MiscPages/Loading";
import OverviewEditor from "./OverviewEditor";
import BioPairsEditor from "./ BioPairsEditor";

// Constants
import { bioPlantContainerScale } from "../../constants";
import BioBubble from "../../BioPage/Body/Bio/BioBubble";

// CSS
import "./PlantEditor";

const EditPlant = ({ plant, species, buttonPressed }) => {
  // Hooks
  const [loaded, setLoaded] = useState(false);
  const [hasCycled, setHasCycled] = useState(false);
  const [plantUpdated, setPlantUpdated] = useState(false);
  const [currentTab, setCurrentTab] = useState("none");

  const updatePlant = (updatedPlant) => {
    plant = updatedPlant;
    setPlantUpdated(true);
  };

  return (
    <React.Fragment>
      {plant && species ? (
        <div
          style={
            loaded && hasCycled ? { display: "block" } : { display: "none" }
          }
        >
          <PlantImage
            plant={plant}
            species={species}
            onLoadCallback={() => {
              setLoaded(true);
            }}
            scale={bioPlantContainerScale}
          />
          <div className="plantEditor">
            <div className="tab">
              <button onClick={() => setCurrentTab("overview")}>
                Overview
              </button>
              <button onClick={() => setCurrentTab("plantSprite")}>
                Plant Sprite
              </button>
              <button onClick={() => setCurrentTab("bioPairs")}>
                Bio Pairs
              </button>
              <button onClick={() => setCurrentTab("bioBubbles")}>
                Bio Bubbles
              </button>
            </div>
            {plantUpdated ? <p>Plant Updated</p> : null}

            <React.Fragment>
              {currentTab === "overview" ? (
                <OverviewEditor
                  plant={plant}
                  plantUpdatedCallback={updatePlant}
                />
              ) : null}
              {currentTab === "plantSprite" ? (
                <p>the sprite edit tools will go here</p>
              ) : null}
              {currentTab === "bioPairs" ? (
                <BioPairsEditor bioPairs={plant.bio} />
              ) : null}
              {currentTab === "bioBubbles" ? (
                <p>The bio bubble editor will go here</p>
              ) : null}
              {/* plant.getBioBubbles().map((bubble, index) => {
                return (
                  <BioBubble
                    key={"bioBubble" + String(index + 1)}
                    data={bubble}
                  />
                );
              }) */}
            </React.Fragment>
          </div>
        </div>
      ) : null}
      {(!loaded || !hasCycled) && plant && species ? (
        <Loading minNumImagesCallback={setHasCycled} minNumImages={3} />
      ) : null}
    </React.Fragment>
  );
};

export default EditPlant;

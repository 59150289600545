// App.jsx
// TK 10/12/2k22

// Packages, dependencies, and library functions
import React, { useEffect } from "react";
import { Route, Routes, BrowserRouter, useNavigate } from "react-router-dom";
import { getRandomPlant } from "./DatabaseOperations";

// React Components
import PlantBioPage from "./BioPage/BioPage";
import Console from "./Console/Console";
import PageNotFound from "./MiscPages/PageNotFound";

// CSS
// @TODO This should probably be App.css??
import "./globals.css";

/*******************************************************************************
 * @func RandomPlant (React Component)
 * This component is called whenever the root URL is displayed. It redirects the
 * page to a random plant
 *
 * @param None
 * @return Null
 *
 * @notes This will redirect the page to a random plant so it's not exactly a
 * real component
 *
 ******************************************************************************/
const RandomPlant = () => {
  const navigate = useNavigate();
  useEffect(() => {
    getRandomPlant().then((plantID) => {
      navigate("/plant/" + plantID);
    });
  }, []);
  return null;
};

/*******************************************************************************
 * @func App (React Component)
 * Called by ReactDOM.render(), this component is the parent of all the other
 * components in the application.
 *
 * @param None
 * @return The 4 possible components that can be displayed from here are
 * 1. Home
 * 2. Plant Bio Page
 * 3. Not Found
 * 4. Console (or its children)
 *
 * @notes This will grow as we add more sections of the website
 *
 ******************************************************************************/
const App = () => {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RandomPlant />} />
          <Route path="/plant/:id" element={<PlantBioPage />} />
          <Route path="/console/*" element={<Console />} />
          {/*  Default when nothing else matches */}
          {<Route path="*" element={<PageNotFound />} />}
        </Routes>
      </BrowserRouter>
    </div>
  );
}; // End App

export default App;

// PlantEditor.jsx
// TK 9/26/2k22

// Packages, dependencies, and library functions
import React, { useState } from "react";
import {
  getPlantFromDatabase,
  getSpeciesFromDatabase,
  updateDB,
} from "../../DatabaseOperations";

// React Components
import ConsoleHomePageHeader from "../Home/ConsoleHomePageHeader";

// CSS
import "./PlantEditor.css";

import * as csv from "csvtojson";

const SpeciesInfoUpload = () => {
  // Hooks
  const [speciesName, setSpeciesName] = useState("");
  const [speciesCamelCase, setSpeciesCamelCase] = useState("");
  const [commonName, setCommonName] = useState("");

  const [stage0Height, setStage0Height] = useState(0);
  const [stage0Size, setStage0Size] = useState("");
  const [stage0Overlap, setStage0Overlap] = useState(0);
  const [stage1Height, setStage1Height] = useState(0);
  const [stage1Size, setStage1Size] = useState("");
  const [stage1Overlap, setStage1Overlap] = useState(0);
  const [stage2Height, setStage2Height] = useState(0);
  const [stage2Size, setStage2Size] = useState("");
  const [stage2Overlap, setStage2Overlap] = useState(0);
  //const [plant, setPlant] = useState(null);
  const [species, setSpecies] = useState(null);
  const [error, setError] = useState("");

  const uploadSpeciesInfo = () => {
    let stages = [
      {
        plantBody: {
          height: Number(stage0Height),
          imageName: speciesCamelCase + "0.png",
          x: 0,
          y: 0,
        },
        plantPotOverlap: Number(stage0Overlap),
        size: stage0Size,
      },
      {
        plantBody: {
          height: Number(stage1Height),
          imageName: speciesCamelCase + "1.png",
          x: 0,
          y: 0,
        },
        plantPotOverlap: Number(stage1Overlap),
        size: stage1Size,
      },
      {
        plantBody: {
          height: Number(stage2Height),
          imageName: speciesCamelCase + ".png",
          x: 0,
          y: 0,
        },
        plantPotOverlap: Number(stage2Overlap),
        size: stage2Size,
      },
    ];

    updateDB(speciesName, "Species", {
      commonName: commonName,
      stages: stages,
    });
    //extract species to an array
    //for each species in the CSV, update data of respective careInstructions,
    // Then get the species of the plant from the Species Collection

    /*updateDB(species[0], "Species", {
      testy: "toast",
      careInstructions: { waterRating: "oof" },
    });*/
    //check for error or print the successful result

    // First get the plant object from the Plants Collection
    /*getPlantFromDatabase(plantID).then(
      (plant) => {
        setPlant(plant);
       
      },
      (reason) => {
        setError(reason);
      }
    );*/
  };

  //Func to add height, imageName, and x, y, and overlap for a given species
  // stage 0/1/2 plantBody height/imageName, + x = 0, y = 0 overlap
  // commonName/nickname
  // to collect:
  // height for each stage
  // overlap for each stage
  // size for each stage
  // species name
  // common name

  return (
    <div>
      <ConsoleHomePageHeader />
      <div className="title">Species Info Upload</div>
      <textarea
        className="textbox plantIDTextbox"
        placeholder="Species name"
        onChange={(e) => {
          setSpeciesName(e.target.value);
        }}
        type="text"
      ></textarea>
      <textarea
        className="textbox plantIDTextbox"
        placeholder="Camel Case"
        onChange={(e) => {
          setSpeciesCamelCase(e.target.value);
        }}
        type="text"
      ></textarea>
      <textarea
        className="textbox plantIDTextbox"
        placeholder="Common Name"
        onChange={(e) => {
          setCommonName(e.target.value);
        }}
        type="text"
      ></textarea>

      <textarea
        className="textbox plantIDTextbox"
        placeholder="Stage 0 Height"
        onChange={(e) => {
          setStage0Height(e.target.value);
        }}
        type="text"
      ></textarea>
      <textarea
        className="textbox plantIDTextbox"
        placeholder="Stage 0 Overlap"
        onChange={(e) => {
          setStage0Overlap(e.target.value);
        }}
        type="text"
      ></textarea>
      <textarea
        className="textbox plantIDTextbox"
        placeholder="Stage 0 Size"
        onChange={(e) => {
          setStage0Size(e.target.value);
        }}
        type="text"
      ></textarea>

      <textarea
        className="textbox plantIDTextbox"
        placeholder="Stage 1 Height"
        onChange={(e) => {
          setStage1Height(e.target.value);
        }}
        type="text"
      ></textarea>
      <textarea
        className="textbox plantIDTextbox"
        placeholder="Stage 1 Overlap"
        onChange={(e) => {
          setStage1Overlap(e.target.value);
        }}
        type="text"
      ></textarea>
      <textarea
        className="textbox plantIDTextbox"
        placeholder="Stage 1 Size"
        onChange={(e) => {
          setStage1Size(e.target.value);
        }}
        type="text"
      ></textarea>

      <textarea
        className="textbox plantIDTextbox"
        placeholder="Stage 2 Height"
        onChange={(e) => {
          setStage2Height(e.target.value);
        }}
        type="text"
      ></textarea>
      <textarea
        className="textbox plantIDTextbox"
        placeholder="Stage 2 Overlap"
        onChange={(e) => {
          setStage2Overlap(e.target.value);
        }}
        type="text"
      ></textarea>
      <textarea
        className="textbox plantIDTextbox"
        placeholder="Stage 2 Size"
        onChange={(e) => {
          setStage2Size(e.target.value);
        }}
        type="text"
      ></textarea>

      <input
        className="getPlantButton"
        type="submit"
        value="Upload Species Info"
        onClick={uploadSpeciesInfo}
      ></input>
      {error !== "" ? <div>{error}</div> : null}
    </div>
  );
};

export default SpeciesInfoUpload;

import React, { useState, useEffect } from "react";
import QRCode from "./QRCode";

import "./QRCodeGenerator.css";
import qrCodeFaceLogo from "./qrCodeFaceLogo.png";
import qrCodeFaceLogo2 from "./qrCodeFaceLogo2.png";
import leafLingsAppIcon from "./leafLingsAppIcon.jpeg";

import { averyTemplates } from "../../constants";
import PlantImage from "../../Sprite/PlantImage";
import {
  getPlantFromDatabase,
  getSpeciesFromDatabase,
} from "../../DatabaseOperations";

const GridItem = (props) => {
  const { plant, template } = props;

  console.log(plant);

  return (
    <div
      className="grid-item"
      style={{
        height: template.gridItemHeight,
        width: template.gridItemWidth,

        margin: template.gridItemMargin,
        padding: template.gridItemPadding,
        textAlign: template.gridItemAlignment,

        backgroundColor: "white",
        //backgroundColor: "rgb(255, 240, 207)",

        /*borderColor: "black",
        borderStyle: "solid",
        borderWidth: "2px",*/
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: "white",

          //backgroundColor: "rgb(255, 240, 207)",
          paddingBottom: "2px",
        }}
      >
        <div className="nameAndCodes">
          <div>
            <div className="helloMyNameIsText">hi! my name is:</div>
            {/*<div className="nameText">{plant.name}</div>
            <div className="speciesText">{plant.species.commonName}</div>*/}
          </div>
          <div>
            <QRCode
              url={"reallifeplants.com/plant/" + plant.plantID}
              backgroundColor={"#ffffff"}
              foregroundColor={plant.color}
              template={template}
            />
            <div
              style={{
                fontSize: "6px",
                textAlign: "center",
                paddingBottom: "0px",
              }}
            >
              {plant.secretCode}
            </div>
          </div>
        </div>
        <div style={{ position: "relative", zIndex: "1" }}>
          <div style={{ height: "110px", zIndex: "1", marginRight: "-20px" }}>
            {/*<img className="sprite" src={leafLingsAppIcon} style={{}}></img>*/}
            <div />
            <PlantImage
              plant={plant.plant}
              species={plant.species}
              width={"60%"} //"65%"}
            />
            <div
              style={{
                textAlign: "center",
                fontSize: "6px",
                opacity: "100%",
              }}
            >
              <em>{plant.plant.species}</em>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridItem;

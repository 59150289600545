// CareBubble.jsx
// DL 9/23/2k22

// Packages, Dependencies, Shared Libraries
import React, { useEffect, useState } from "react";

// CSS
import "./Care.css";

export const CareBubble = ({ data }) => {
  // Constants
  const images = require.context(
    "../../../Images/PlantBioPage/BubbleIcon",
    true
  ); // Image Context

  const drawWaterIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" height="30" width="30">
        <circle
          cx="15"
          cy="20"
          r="8.5"
          stroke="blue"
          strokeWidth="1"
          fill="blue"
        />
        <polygon
          points="8,15 15,4 22,15"
          stroke="blue"
          strokeWidth="1"
          fill="blue"
          strokeLinejoin="round"
        />
      </svg>
    );
  }; // End drawWaterIcon

  /* drawLightIcon
    Draws the sunlight icon for the care instructions

    @param None
    @return svg element of a sun icon
  */
  const drawLightIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" height="30" width="30">
        <circle
          cx="15"
          cy="17"
          r="10.5"
          stroke="orange"
          strokeWidth="4"
          fill="yellow"
          strokeDasharray="1,2"
        />
      </svg>
    );
  }; // End drawLightIcon

  /* drawPlusIcon
    Draws the plus sign for the care instructions

    @param None
    @return svg element of a green plus sign
  */
  const drawPlusSign = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" height="30" width="30">
        <polygon
          points="5,13 25,13 25,17 5,17"
          stroke="green"
          strokeWidth="3"
          fill="green"
          strokeLinejoin="round"
        />
        <polygon
          points="13,5 13,25 17,25 17,5"
          stroke="green"
          strokeWidth="3"
          fill="green"
          strokeLinejoin="round"
        />
      </svg>
    );
  }; // End drawPlusSign

  /* drawImage
    Selects the icon to render based on the image prop

    @param None
    @return myDrawingFunction (function): One of the
    render functions defined above, used to render this
    components image
  */
  const drawImage = () => {
    let myDrawingFunction;
    switch (data.image) {
      case "water":
        myDrawingFunction = drawWaterIcon;
        break;
      case "light":
        myDrawingFunction = drawLightIcon;
        break;
      case "plusSign":
        myDrawingFunction = drawPlusSign;
        break;
      default:
        myDrawingFunction = drawPlusSign; // Update with a default
    }
    return myDrawingFunction();
  }; // End drawImage

  return (
    <div className="careBubble">
      {drawImage()}
      <div className="careBubbleTextContainer">
        <div className="careBubbleText">{data.instruction}</div>
      </div>
    </div>
  );
}; // End BubbleItem

export default CareBubble;

//export default CareContainer;

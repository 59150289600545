// Analytics.jsx
// TK 7/13/2k22

// Packages, dependencies, and library functions
import React from "react";

// Components
import ConsoleHomePageHeader from "../Home/ConsoleHomePageHeader";

const Analytics = () => {
  return (
    <div>
      <ConsoleHomePageHeader />
      <div>This will be the analytics page</div>
    </div>
  );
};

export default Analytics;

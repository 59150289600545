// Loading.jsx
// DL 10/2/2k22

// Packages, Dependencies, and Shared Libraries
import React, { useEffect, useRef, useState } from "react";

// CSS
import "./MiscPages.css";

// Images
import loadingPot from "../Images/Loading/loadingPot.png";
import image1 from "../Images/Loading/Loading1.png";
import image2 from "../Images/Loading/Loading2.png";
import image3 from "../Images/Loading/Loading3.png";
import loadingMouth from "../Images/Loading/loadingMouth.png";
import loadingEyes from "../Images/Loading/loadingEyes.png";
const images = [image1, image2, image3];

/* Loading Component
  This component is displayed while the plant bio page
  is loading. It shows three images at an interval
  where the plant will grow from a sprout to a bigger plant.

  @param minNumImagesCallback (function): The callback function fires
  when the minimum number of images have cycled. This gets passed
  sets the hasCycled state in the parent component (plant bio page)
  to true.
  @param minNumImages (numeric): The minimum number of images that
  must display before the plant renders. This should just be the
  number of unique images.
*/
const Loading = ({ minNumImagesCallback, minNumImages }) => {
  // Hooks
  const [imageURL, setImageURL] = useState(images[0]); // The url of the image displayed
  const [count, setCount] = useState(1); // The number of images displayed already
  const mounted = useRef(false); // Used to check if the component has mounted

  /*
    This useEffect changes the mounted.current state
    to track whether the component has mounted or
    been unmounted
  */
  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  });

  /* selectImage
    Selects an image from the images array based
    on the count. The image returned is always the
    modulo of the length of the array.

    @param: None
    @return Promise:
      resolve -> index, which is the index of the image
      to load next
      reject -> None
  */
  const selectImage = () => {
    return new Promise((resolve) => {
      if (count === minNumImages) {
        minNumImagesCallback(true);
      }
      resolve(count % images.length);
    });
  }; // End selectImage

  /* 
    This use effect is fired whenever the imageURL updates
    Function waits 200ms, then cycles to next image 
  */
  useEffect(() => {
    setTimeout(() => {
      selectImage().then((index) => {
        if (mounted.current) {
          setCount(count + 1);
          setImageURL(images[index]);
        }
      });
    }, 200);
    // eslint-disable-next-line
  }, [imageURL]);

  return (
    <div className="loadingPage">
      <div className="loadingContainer">
        <img className="loadingTopImage" src={imageURL} alt={imageURL} />
        <img className="loadingPotImage" src={loadingPot} alt={"Loading Pot"} />
        <img
          className="loadingPotImage"
          src={loadingEyes}
          alt={"Loading Eyes"}
        />
        <img
          className="loadingPotImage"
          src={loadingMouth}
          alt={"Loading Mouth"}
        />
        {/*<img className="potLogo" src={logo} alt="LeafLings" />*/}
      </div>
    </div>
  );
}; // End Loading

export default Loading;

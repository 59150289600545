// Takes a Batch Generation ID and creates a pdf with QR Codes
// and other info

//Take in a String (Batch ID) from the user
//Check if it's valid
//Get the data from that Batch
//Parse the data
//Lay it out as a pdf
//Download the PDF

import {
  getBatch,
  getPlantFromDatabase,
  getSpeciesFromDatabase,
} from "../../DatabaseOperations";
import { useRef, useEffect, useState } from "react";

// Might want to switch to domtoimage
// https://stackoverflow.com/questions/22803825/html2canvas-generates-blurry-images
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import QRCode from "./QRCode";
import QRCodeLayout from "./QRCodeLayout";

import { averyTemplates } from "../../constants";

const exportAsImage = async (element, imageFileName) => {
  console.log(element);

  const canvas = await html2canvas(element, {
    scale: 5,
    allowTaint: true,
    useCORS: true,
    letterRendering: true,
  });
  const image = canvas.toDataURL("image/png", 1.0);
  var pdf = new jsPDF("portrait", "mm", "letter");

  let height = (750 * 3.0) / 8;
  let width = (568 * 3.0) / 8; //(579.5 * 3.0) / 8; //
  pdf.addImage(image, "JPEG", 0, 0, width, height);
  pdf.save("download.pdf");
  //console.log(image);
  //downloadImage(image, imageFileName);
};

const downloadImage = (blob, fileName) => {
  const fakeLink = window.document.createElement("a");
  fakeLink.style = "display:none;";
  fakeLink.download = fileName;

  fakeLink.href = blob;

  document.body.appendChild(fakeLink);
  fakeLink.click();
  document.body.removeChild(fakeLink);

  fakeLink.remove();
};

const dummyData = {
  generatedPlants: [
    /*{
      plantID: "Test",
      secretCode: "XXXXXXXX",
      name: "Billy",
      plantType: "xxxx",
    },*/
    {
      plantID: "",
      secretCode: "",
      name: "",
      plantType: "",
      plant: {},
      species: {},
    },
  ],
};

const offlineMode = false;

const TagGeneration = () => {
  const [plants, setPlants] = useState(dummyData.generatedPlants);
  const [batchID, setBatchID] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [hasGeneratedPDF, setHasGeneratedPDF] = useState(false);

  //should be a dropdown
  const [selectedTemplate, setSelectedTemplate] = useState(0);

  useEffect(() => {
    if (submitted) {
      if (batchID !== "") {
        downloadBatch(batchID);
      }
    }
  }, [submitted]);

  const processBatchData = (data) => {
    let plantsInBatch = data.generatedPlants;

    let newPlants = [];
    for (let i = 0; i < plantsInBatch.length; i++) {
      newPlants.push(plantsInBatch[i]);
    }
    const template = averyTemplates[selectedTemplate];
    while (newPlants.length < template.rows * template.columns) {
      newPlants.push(plantsInBatch[0]);
    }

    //let plantsToAdd = newPlants;
    let plantsAreReady = newPlants.length;
    newPlants.map((newPlant) => {
      if (newPlant.plantID !== "") {
        // First get the plant object from the Plants Collection
        getPlantFromDatabase(newPlant.plantID).then(
          (plant) => {
            newPlant.plant = plant;

            // Then get the species of the plant from the Species Collection
            getSpeciesFromDatabase(plant.species).then(
              (species) => {
                newPlant.species = species;
                plantsAreReady = plantsAreReady - 1;
                if (plantsAreReady === 0) {
                  setPlants(newPlants);
                  setHasGeneratedPDF(true);
                }
              },
              (reason) => {
                //setError(reason);
              }
            );
          },
          (reason) => {
            //setError(reason);
          }
        );
      } else {
        //setError("Plant Not Found!");
      }
    });
  };

  const downloadBatch = (batchID) => {
    //setPlants([]);
    if (offlineMode) {
      console.log("offlineMode");
      processBatchData(dummyData);
      setHasGeneratedPDF(true);
    } else {
      getBatch(batchID)
        .then((doc) => {
          if (doc.exists) {
            if (doc.data()) {
              //process
              processBatchData(doc.data());
            }
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });

      /*then((response, error) => {
      if (error) {
        return console.log("error");
      }
      
    });*/
    }
  };

  const handleBatchIDInputChange = (event) => {
    event.persist();
    setBatchID(event.target.value);
    setSubmitted(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
  };

  const downloadRef = useRef();

  const setLabelFormat = (e) => {
    //e.preventDefault();
    setSelectedTemplate(e.target.value);
  };

  return (
    <div
      style={{
        backgroundColor: "rgb(255, 240, 207)",
      }}
    >
      <form onSubmit={handleSubmit}>
        <label>
          Batch ID:
          <input type="text" name="name" onChange={handleBatchIDInputChange} />
        </label>
        <input type="submit" value="Submit" />
      </form>
      <p>Select label format:</p>
      <div onChange={setLabelFormat.bind(this)}>
        <input type="radio" value="0" name="labelFormat" /> 1.25 x 2.25
        <input type="radio" value="1" name="labelFormat" /> 1.25 x 2
      </div>

      {hasGeneratedPDF ? (
        <div>
          <button
            onClick={() => {
              exportAsImage(downloadRef.current, "test");
            }}
          >
            download
          </button>
          <QRCodeLayout
            plants={plants}
            downloadRef={downloadRef}
            selectedTemplate={selectedTemplate}
          />
        </div>
      ) : null}
    </div>
  );
};

export default TagGeneration;

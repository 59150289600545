// Bio.jsx

import React from "react";

// Components
import PromptResponse from "./PromptResponse";
import BioBubble from "./BioBubble"; //Uncomment when added back
import "../../../constants";
import PopUpButton from "../PopUpButton";

// CSS
import "./Bio.css";
import { PLANTSONALITY_DESC } from "../../../constants";
/* Bio Component
  Displays the "Bio" section for the plant. Has the
  prompt and response answers, as well as the bio
  bubbles.

  @param plant (plant object): Includes all the information
  about the plant to render it's bio
*/
const Bio = ({ plant }) => {
  return (
    <React.Fragment>
      <div className="contentSection" id="bio">
        <b className="bioTitleSection">
          <div>{plant.name + "'s Plantsonality"}</div>
          <div style={{ marginTop: "-5px" }}>
            <PopUpButton
              style={{
                border: "2px solid black",
                fontSize: "12px",

                height: "22px",
                width: "22px",
                marginLeft: "5px",
                borderRadius: "11px",
                color: "black",
                padding: "0",

                backgroundColor: "white",
              }}
              buttonText="i"
              popUpText={PLANTSONALITY_DESC}
              plantName={plant.name}
            />
          </div>
        </b>
        <div className="bioBubbleContainer">
          {plant.bioBubbles.map((bioBubble, index) => {
            return (
              <BioBubble
                bioBubble={bioBubble}
                key={"bioBubble" + String(index + 1)}
              ></BioBubble>
            );
          })}
        </div>
        {plant.bio.map((promptResponse, index) => {
          return (
            <PromptResponse
              key={"bioAnswer" + String(index + 1)}
              promptResponse={promptResponse}
            />
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default Bio;

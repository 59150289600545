// PromptResponse.jsx
// DL 5/22/2k22

// Packages, Dependencies, Shared Libraries
import React from "react";

// CSS
import "./Bio.css";

/* PromptResponse Component
  Component contains a list of prompt and response
  text pairs that are rendered from the bio object array.

  @param promptResponse: object with structure: {
    prompt (string): prompt to be displayed
    response (string): response to be displayed
  }
*/
const PromptResponse = ({ promptResponse }) => {
  // Displays all the pairs in the array
  // This way we can have a dynamic amount of
  // bio prompt-response pairs
  return (
    <div className="bioPair">
      <p className="prompt">{promptResponse.prompt}</p>
      <p className="response">{promptResponse.response}</p>
    </div>
  );
}; // End PromptResponse

export default PromptResponse;

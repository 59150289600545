// sharedFunctions.js
// TK 06/01/2022

/*******************************************************************************
 * @func openInNewTab
 * Opens a link in a new browser tab.
 *
 * @param link (string): link to open in new tag
 * @return None
 *
 * @notes None
 *
 ******************************************************************************/
export const openInNewTab = (link) => {
  var win = window.open(link, "_blank");
  win.focus();
};

// DatabaseOperations.js
// TK 09/26/2k22

// Packages, Dependencies, and Shared Library functions
import db from "./Firebase";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import {
  collection,
  doc,
  get,
  getDoc,
  setDoc,
  where,
  getDocs,
  query,
  limit,
  serverTimestamp,
  Timestamp,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";

/*******************************************************************************
 *  DatabaseOperations.js
 *
 *
 * This file contains many of the functions used to make calls to request or add
 * data to the Firestore database. Anything that directly makes calls to the
 * firebase database should be placed in this file, with appropriate validation
 * and error reporting.
 *
 * Most of these functions are asynchronous, therefore we
 * should stick to the convention of using promises instead of async/await.
 *
 *
 ******************************************************************************/

/*******************************************************************************
 *
 * @func getDocRef
 * Gets the document reference from Google Firebase
 *
 * @param docID (string): Unique DB document ID string
 * @param collection (string): Which DB collection to read from
 * @return Promise:
 * - On Resolve, returns the plant document Reference
 * - On Reject, none, doc() always returns some location, but error handling
 *   will be handled once we attempt to get the document
 *
 * @notes None
 *
 ******************************************************************************/
const getDocRef = (docID, collection) => {
  // Use a promise so the other functions after this don't try to execute before
  // this data has been queried
  return new Promise((resolve) => {
    const plantRef = doc(db, collection, docID);
    resolve(plantRef);
  });
}; // End getDocRef

/*******************************************************************************
 * @func getPlantFromDatabase
 * Returns the plant document's data based on the plantID passed in
 *
 * @param plantID (string): Unique doc ID of the plant in the DB
 * @return Promise:
 * - On Resolve: Returns the plant document's data from the DB query
 * - On Reject: Reason for Failure
 *
 * @notes None
 *
 ******************************************************************************/
export const getPlantFromDatabase = (plantID) => {
  return new Promise((resolve, reject) => {
    // First get the unique plant doc reference
    getDocRef(plantID, "Plants").then(
      (plantRef) => {
        // Then get the plant document
        getDoc(plantRef).then(
          (plant) => {
            if (plant.exists()) {
              // Return the data of the plant document
              resolve(plant.data());
            } else {
              // This plant doesn't exist in the DB...
              reject("Plant Document does not exist!");
            }
          },
          (reason) => {
            // Pass the error back up
            reject(reason);
          }
        );
      },
      null // This will always return with a resolve
    );
  });
}; // End getPlantFromDatabase

/*******************************************************************************
 * @func getSpeciesFromDatabase
 * Returns the species document's data based on the species of the plant
 *
 * @param species (String): The name of species to get the information for
 * @return Promise:
 * - On Resolve: Returns the species document's data from the DB query
 * - On Reject: Reason for Failure
 *
 * @notes None
 *
 ******************************************************************************/
export const getSpeciesFromDatabase = (species) => {
  // Get the Reference to the species doc
  return new Promise((resolve, reject) => {
    getDocRef(species, "Species").then((stdPlantRef) => {
      // Get the doc for the species type
      getDoc(stdPlantRef).then(
        (species) => {
          if (species.exists()) {
            // Return the data of the species document
            resolve(species.data());
          } else {
            // Standard Species DB entry does not exist
            reject("Standard Species Document does not exist!");
          }
        },
        (reason) => {
          // Pass the error back up
          reject(reason);
        }
      );
    }, null); // This will always return with a resolve
  });
};

/*******************************************************************************
 * @func getImageRef
 * Returns the image reference for an image kept in the firebase storage
 *
 * @param imagePath (string): Relative path of the image in the storage
 * @return ref (image Reference): A reference of the image from the DB, which
 * will be used to get the URL of the image
 *
 * @notes None
 *
 ******************************************************************************/
const getImageRef = (imagePath) => {
  return ref(getStorage(), imagePath);
}; // End getImageRef

/*******************************************************************************
 * @func getImageURLFromDatabase
 * Returns the remote URL of the image hosted in the firebase storage
 *
 * @param imagePath (string): Relative path of the image in the storage
 * @return Promise:
 * - On Resolve: Returns the remote URL of the image
 * - On Reject: Passes the reason for rejection (from DB) back to the caller
 *
 * @notes None
 *
 ******************************************************************************/
export const getImageURLFromDatabase = (imagePath) => {
  return new Promise((resolve, reject) => {
    getDownloadURL(getImageRef(imagePath)).then(
      (url) => {
        resolve(url);
      },
      (reason) => {
        reject(reason); // Just return the error if issues geting URL
      }
    );
  });
}; // End getImageURLFromDatabase

/*******************************************************************************
 * @func updateDB
 * Updates a doc in the database
 *
 * @param docID (string): The document ID of the entry in the collection
 * @param collection (string): The the name of the collection that contains the
 * doc we're trying to update
 * @param updates (object): The updates that need to be merged into the database
 * entry
 * @return Promise:
 * - On Resolve (string): Returns empty string ("") for success
 * - On Reject (string): Returns the reason for the rejection
 *
 * @notes Definitely need to check and see how this is used in the feedback
 * reporting and also ensure that it will meet our needs for editing plants
 *
 ******************************************************************************/
export const updateDB = (docID, collection, updates) => {
  return new Promise((resolve, reject) => {
    getDocRef(docID, collection).then((docRef) => {
      setDoc(docRef, updates, { merge: true }).then(
        () => {
          resolve("");
        },
        (reason) => {
          reject(reason); // Pass reason back to caller
        }
      );
    });
  }, null); // This will always return something valid
}; // End updateDB

/*******************************************************************************
 * @func getRandomPlant
 * Returns a random plant ID from the database
 *
 * @param None
 * @return plantID (string): The document ID of the random plant
 *
 * @notes This could definitely be refined and optimized. This was kinda a bitch
 * to get working for some reason but it was stiched together from a bunch of
 * different articles and stack overflow posts. Just on my experience, it seems
 * like we get certain plants with more frequency than others. But fine for now
 *
 ******************************************************************************/
export const getRandomPlant = () => {
  return new Promise((resolve, reject) => {
    const plants = collection(db, "Plants"); // Reference to plant collection
    let plantID = ""; // Initialize to empty

    const key = doc(plants).id; // Get a randomly generated doc ID

    // First query
    let q = query(plants, where("__name__", ">=", key), limit(10));

    getDocs(q).then((snapshot) => {
      // Check to see if we got something back
      if (snapshot.size > 0) {
        // If we did, randomly return one of the plants
        plantID =
          snapshot.docs[Math.round(Math.random() * (snapshot.size - 1))].id;
      } else {
        // If not, try looking below
        q = query(plants, where("__name__", "<", key), limit(10));
        getDocs(q).then((snapshot) => {
          // Check to see if we got one back
          if (snapshot.size > 0) {
            // If we did, randomly return one of the plants
            plantID =
              snapshot.docs[Math.round(Math.random() * (snapshot.size - 1))].id;
          } else {
            // Otherwise reject with the error message
            reject("Could not find a random plant");
          }
        });
      }
      resolve(plantID);
    });
  });
}; // End getRandomPlant

/*******************************************************************************
 * @func getBatch
 * Returns the doc reference for a plant batch based on the generation batch ID
 *
 * @param bathID (string): The ID of the batch generation document we are trying
 * to retrieve
 * @return document (Google Firebase Document Object): The document from the
 * batch generation database
 *
 * @notes None
 *
 ******************************************************************************/
export const getBatch = (batchID) => {
  const batchRef = doc(db, "BatchGeneration", batchID);

  return getDoc(batchRef);
}; // End getBatch

/*******************************************************************************
 * @func getUserByEmail
 * Returns a document from the Users DB Collection based on ther email
 *
 * @param email (string): The email of the user
 * @return Promise
 * - On Resolve (user object): The user document's data
 * - On Reject (string): The reason for the query failure
 *
 * @notes None
 *
 ******************************************************************************/
export const getUserByEmail = (email) => {
  return new Promise((resolve, reject) => {
    getDocs(query(collection(db, "Users"), where("email", "==", email))).then(
      (queryRef) => {
        // Check how many users we get b/c there should only be one.
        // Theoretically, this should never occur because future Signup and user
        // profile process should run duplicate email checks (I hope)
        // Also there could be no doc associated with that email
        if (queryRef.size === 1) {
          // Since I'm a JS noob, there is probably a better way to do this
          queryRef.forEach((user) => {
            resolve(user.data());
          });
          // No Users found with the email
        } else if (queryRef.size < 1) {
          reject("No users found with this email");
        } else {
          // More than one
          reject("Multiple users with the same email");
        }
      }
    );
  });
};

/*******************************************************************************
 * @func recordVisit
 * Adds a record of a visit to the plant's scan log
 *
 * @param plantID (string): The ID of the plant visited
 * @return null
 *
 * @notes None
 *
 ******************************************************************************/

export const recordVisit = (plantID) => {
  getDocRef(plantID, "PilotScanTracking").then((feedbackRef) => {
    getDoc(feedbackRef).then((doc) => {
      console.log(doc.data());
      if (doc.exists()) {
        console.log("Found this document");
        updateDoc(feedbackRef, {
          scans: arrayUnion(Timestamp.now()),
        });
      } else {
        setDoc(feedbackRef, {
          scans: arrayUnion(Timestamp.now()),
        });

        console.log("no scans document found");
      }
    });
  });
};

/*var docRef = db.collection("PilotScanTracking").doc(plantID);

  getDocRef(plantID, "PilotScanTracking")
    .then((doc) => {
      if (doc.exists) {
        updateDoc(doc, {
          scans: arrayUnion(Timestamp.now()),
        });
        console.log("Document data:", doc.data());
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });

  */
